@import "~@/styles/themes.scss";
.containerDrawer {
  margin: -24px;
}

.topContainerDrawer {
  display: flex;
  align-items: center;
  padding: 20px;
  background-color: $pwa-secondary-color;
  position: relative;
}

.progressText {
  font-family: $primary-font;
  color: $pwa-grey-2;
  font-size: 11px;
  font-weight: 200;
  position: absolute;
  bottom: 15%;
}

.quitBtn {
  height: 38px;
  width: 62px;
  border-radius: 11px;
  background-color: white;
  position: absolute;
  top: 30%;
  right: 10%;
  box-shadow: $admin-main-shadow;
  span {
    // color: orange;
    font-family: $primary-font;
    font-size: 16px;
    font-weight: 600;
  }
}

.titleText {
  font-family: $secondary-font;
  font-size: 24px;
  font-weight: bold;
  // color: white;
}

.quizNotExist{
  font-family: $secondary-font;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  margin-top: 50px;
}

.quizTitleText {
  font-family: $secondary-font;
  font-size: 16px;
  font-weight: bold;
  // color: white;
  line-height: 1;
  margin-bottom: 10px;
  width: 80%;
  padding-right: 10px;
}

.drawerResultContent {
  display: flex;
  align-items: center;
  justify-content: center;
}
.backToModule {
  height: 40px;
  width: 40px;
  border-radius: 15px;
  background-color: white;
  float: right;
  // color: orange;
  display: flex;
  justify-content: center;
  align-items: center;
}

.divider {
  background-color: #000;
  height: 1px;
  width: 100%;
  margin-top: 50px;
}

.quizResult {
  font-family: $secondary-font;
  font-size: 16px;
  // color: white;
  margin-top: 20px;
}

.quizAnsweredQuestion {
  font-family: $secondary-font;
  font-size: 14px;

  // color: white;
  margin-top: 25px;
}

.answerText {
  // color: #ffffff;
  font-family: $primary-font;
  opacity: 70%;
}

.resultText {
  font-family: $primary-font;
  opacity: 0.8;
  color: $pwa-grey-2;
}

.goBackToTrainingCentre {
  font-family: $primary-font;
  display: flex;
  border-radius: 15px;
  border-color: rgba(255, 255, 255, 0.3);
  justify-content: center;
  width: 80%;
  box-shadow: $admin-main-shadow;
  background-color: rgba(255, 255, 255, 0.3);
  // color: white;
  position: absolute;
  bottom: 6%;
  left: 10%;
  height: 60px;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  border-color: rgba(255, 255, 255, 0.1);
}

.quizContent {
  color: #132257;
  font-family: $secondary-font;
  font-weight: 600;
  font-size: 16px;
  margin-top: 10%;
  margin-left: 5%;
}

.unselected {
  width: 90%;
  left: 5%;
  border-radius: 15px;
  background-color: white;
  box-shadow: $admin-main-shadow;
  text-align: left;
  height: auto;
  padding: 12px;
  border: none;
  span {
    font-family: $primary-font;
    font-size: 16px;
    color: #1b1b1b;
    line-height: 20px;
    white-space: normal;
  }
}

.selected {
  width: 90%;
  left: 5%;
  border-radius: 15px;
  background: $pwa-secondary-color;
  box-shadow: $admin-main-shadow;
  text-align: left;
  height: auto;
  padding: 12px;
  border: none;
  &:focus,
  &:hover {
    background: $pwa-secondary-color;
    color: #0A0B09;
  }
  span {
    font-family: $primary-font;
    font-size: 16px;
    color: #0A0B09;
    line-height: 20px;
    white-space: normal;
  }
}

.carouselContainer{
  // opacity: 0.4;

  :global{
    .ant-carousel .slick-slider {
      // background: yellow;
      display: flex;
    }

    .ant-carousel .slick-slider .slick-track, .ant-carousel .slick-slider .slick-list {
      display: flex;
      align-items: center;
      // background-color: orange;
    }
    
  }
}


.carousel {
  background: #FCEA25;
  width: 90%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 15px;
  margin-top: 20%;
  box-shadow: $admin-main-shadow;

  :global{
    
  }
}

.carousel img {
  display: block;
  height: 100%;
  width: 100%;
  border-radius: 8px;
  object-fit: cover;
}

.carousel video {
  display: block;
  height: 10%;
  width: 100%;
  border-radius: 8px;
  object-fit: cover;
}
.carousel ul li {
  background: #FCEA25;
  bottom: -35px;
}

.quizBtn,
.quizBtn:focus,
.quizBtn:hover {
  height: 60px;
  width: 60px;
  border-radius: 15px;
  background-color: $pwa-secondary-color;
  border: none;
  position: relative;
  float: right;
  margin-top: 25px;
  margin-right: 12px;
}

.arrow {
  // color: white;
  font-size: 22px;
}

.quizResultPageContainer {
  margin: -24px;
  min-height: 100vh;
  background-color: $pwa-secondary-color;
  padding: 20px;
}

.quizResultPageInnerContainer {
  height: 77vh;
  overflow: auto;
}
