@import "~@/styles/themes.scss";
.containerDrawer {
  margin: -24px;
}


.topContainerDrawer {
  display: flex;
  align-items: center;
  padding: 24px;
  background-color: #FCEA25;
  position: relative;
  
  .progressText {
    font-family: $primary-font;
    // color: rgba(255, 255, 255, 0.699);
    font-size: 11px;
    font-weight: 200;
    position: absolute;
    bottom: 15%;
  }

  .title {
    // color: white;
    font-size: 16px;
  }
  .rightIconWhite {
    // color: white;
    font-size: 24px;
    margin-right: 15px;
    position: relative;
    margin-bottom: -15px;
  }
}

.titleText {
  font-family: $secondary-font;
  font-size: 16px;
  font-weight: bold;
  color: #000;
  padding-bottom: 3px;
}

.carouselContainer{
  // opacity: 0.4;

  :global{
    .ant-carousel .slick-slider {
      // background: yellow;
      display: flex;
    }

    .ant-carousel .slick-slider .slick-track, .ant-carousel .slick-slider .slick-list {
      display: flex;
      align-items: center;
      // background-color: orange;
    }
  }
}
.carousel {
  // background: #FCEA25;
  // opacity: 0.5;
  width: 90%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 8px;
  margin-bottom: 30px;
  box-shadow: $admin-main-shadow;

}

.carousel img {
  // display: block;
  // height: 100%;
  // width: 100%;
  border-radius: 8px;
  // object-fit: cover;
}

.carousel video {
  // display: block;
  // height: 10%;
  // width: 100%;
  border-radius: 8px;
  // object-fit: cover;
}
.carousel ul li {
  background: $pwa-grey-3;
  bottom: -40px;
}

.topicBtn,
.topicBtn:focus,
.topicBtn:hover {
  height: 60px;
  width: 60px;
  border-radius: 15px;
  background-color: $pwa-secondary-color;
  border: none;
}

.arrow {
  // color: white;
  font-size: 22px;
}

.titleText {
  font-family: $secondary-font;
  font-size: 16px;
  font-weight: bold;
  // color: white;
  padding-bottom: 3px;
}

.topicTitle {
  color: $pwa-grey-1;
  font-size: 18px;
  font-weight: bold;
  font-family: $secondary-font;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 10px;
  padding-left: 20px;
}

.topicContent {
  white-space: pre-line;
  text-align: left;
  padding: 10px;
  padding-left: 20px;
  margin-top: 10px;
  padding-bottom: 100px;
}

.htmlcontainer{
  img {
    width: 100%;
    object-fit: contain;
  }
}
