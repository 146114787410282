@import "~@/styles/themes.scss";
@import '../../../styles/themes.scss';

// BRIEFING CONTAINER

.Briefing {
  margin-top: 60px;
  padding: 0 20px 0 20px;
}

// COLLAPSIBLE BRIEFING

.title {
  color: $pwa-grey-1;
  font-family: $secondary-font;
  font-size: 20px;
  font-weight: bold;
  padding-top: 18px;
  padding-bottom: 18px;
}

.divider {
  background-color: #cfd2dd;
  height: 1px;
  width: 100%;
}

.topicContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  scrollbar-width: none;
}

.briefingHtmlContainer {
  max-width: 100%;
  .htmlcontainer {
    img {
      max-width: 100%;
      object-fit: cover;
    }
  }
}

// BRIEFING SEARCH INPUT

.inputContainer {
  :global {
    .ant-input-affix-wrapper {
      background-color: #f5f5f5;
      padding: 18px;
      border-radius: 15px;
      box-shadow: inset 0 3px 6px 0 rgba(0, 0, 0, 0.2);
    }

    .ant-input-affix-wrapper > input.ant-input {
      background-color: #f5f5f5;
      font-size: 16px;
    }
  }
}

.searchIcon {
  color: #8c8c8c;
  margin-right: 10px;
}

// WORKER AVAILABILITY SELECTION

.eventBtncontainer {
  padding: 20px;
  display: flex;
  gap: 20px;
}

.availabilityQuestion {
  display: flex;
  justify-content: center;
  font-weight: 700;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}



.modal {
  border-top-left-radius:40px ;
  border-top-right-radius:40px ;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 80%;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: hidden;
  z-index: 1000;
}

.modalContent {
  position: relative;
  background-color: white;
  height: 100%;
  padding: 20px;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.3);
  overflow-y: auto;
  
  .reason__text_area{
    height: 200px;
    width: 100%;
    border-radius: 10px;
    font-size: 20px;
    padding: 20px;
    margin-top: 10px;
  }
}

// .signUpButtonContainer {
//   position: absolute;
//   width: calc(100% - 60px);;
//   top: calc(100% - 100px);
//   background-color: $pwa-secondary-color;
//   height: 60px;
//   border-radius: 10px;
//   right: 30px;
//   justify-content: 'space-between';
//   align-items: 'center';
//   padding-top: 20px;
//   display: flex;

//   .signUpButton {
//     font-style: normal;
//     font-weight: 700;
//     font-size: 16px;
//     line-height: 20px;
//     margin-left: 15px;
//   }

//   .buttonIcon {
//     margin-left: calc(18%);;;
//   }
// }
