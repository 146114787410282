@import "~@/styles/themes.scss";
.container {
  margin: -24px;
  height: 100vh;
  background-color: $pwa-secondary-color;

  // background: url('/images/quiz-background@2x.jpg');
  // background-repeat: no-repeat;
  // background-size: cover;
}

.topSection {
  position: relative;
  padding: 30px;
  height: 80vh;
  // display: flex;
  // align-items: center;
}

.titleText {
  font-family: $secondary-font;
  font-size: 24px;
  font-weight: bold;
  // color: white;
  display: inline-block;
}

.readingText {
  font-family: $primary-font;
  font-size: 16px;
  font-weight: 600;
  // color: white;
  opacity: 0.7;
}

.backToModule {
  display: flex;
  margin-top: 5px;
  height: 36px;
  width: 36px;
  border-radius: 12px;
  background-color: white;
  float: right;
  border: none;
  box-shadow: 0 6px 10px 0 rgba(0,0,0,0.08), 0 1px 18px 0 rgba(0,0,0,0.1), 0 3px 5px 0 rgba(0,0,0,0.1);
  align-items: center;
  justify-content: center;
}

.middleSection {
  position: absolute;
  bottom: 30px;
  width: 84%;
}

.goToQuizButton {
  padding: 6px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  padding: 24px;
  background-color: white;
  position: relative;
  bottom: -20px;
  width: 100%;
  float: center;
  justify-content: space-between;
}

.tailSection {
  position: absolute;
  width: 100vw;
  bottom: 0;
  color: white;
  background-color: black;
  padding: 25px;
}

.goToNextModule {
  padding: 6px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  padding: 24px;
  background-color: white;
  position: relative;
  width: 100%;
  float: center;
}

.backBtn {
  padding: 6px;
  border: 0px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  padding: 24px;
  background-color: rgba(255,255,255,0.3);
  position: relative;
  width: 100%;
  float: center;
  color: white;
  height: 60px;
  font-size: 16px;
  font-weight: 600;
  font-family: $primary-font;
}

.rightArrow {
  text-align: right;
  display: internal;
}

.middleSection {
  // color: white;
}

.readText {
  font-family: $secondary-font;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 15px;
}

.takeQuizText {
  font-family: $secondary-font;
  font-size: 16px;
  font-weight: 600;
}

.takeQuizTextBlack {
  font-family: $secondary-font;
  font-size: 16px;
  font-weight: 600;
  color: $pwa-grey-1;
}

.icon {
  // color: $pwa-secondary-color;
}

.iconQuiz {
  color: $pwa-grey-1;
  margin-right: 5px;
}
