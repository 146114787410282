@import "~@/styles/themes.scss";
@import '../../../styles/themes.scss';

.container {
  margin-top: 70px;
  .dayTitle{
    font-family: $secondary-font;
    font-size: 18px;
    font-weight: bolder;
    color: $pwa-grey-1;
    margin-left: 30px;
    margin-bottom: 30px;
    display: inline-block;
    
    .dayBorder{
      height: 5px;
      background-color: yellow;
      margin-top: -5px;
    }
  }
  .scheduleDate{
    font-size: 16px;
    font-weight: 600;
    font-family: $admin-title-font;
    color: $admin-primary-color;
    margin-bottom: 20px;
    margin-left: 20px;
    display: inline-block;

    .yellowLine{
      height: 6px;
      background-color: #FCEA25;
      margin-top: -5px;
      border-radius: 3px;
    }
  }
  
}

.circle {
  background-color: $pwa-primary-color;
  width: 11px;
  height: 11px;
  border-radius: 5.5px;
}

.verticalLine {
  height: 100%;
  width: 1px;
  background-color: $pwa-grey-6;
  margin-left: 5px;
}

.descriptionContainer {
  margin-left: 15px;
  margin-top: -5px;
  margin-bottom: 30px;
}

.time {
  font-family: $secondary-font;
  font-size: 14px;
  font-weight: bold;
  color: $pwa-grey-1;
}

.title {
  font-size: 16px;
  color: $pwa-grey-2;
}

.notes {
  font-size: 14px;
  color: $pwa-status-red;
  margin-top: 5px;
}

.notesContainer {
  display: flex;
  align-items: center;
}

.notesIcon {
  margin-top: 6px;
  color: $pwa-status-red;
  margin-right: 5px;
}
