@import "~@/styles/themes.scss";
.containerDrawer {
  margin: -24px;
}

.topContainerDrawer {
  display: flex;
  align-items: center;
  padding: 24px;
  background-color: #FCEA25;
  position: relative;

  .progressText {
    font-family: $primary-font;
    // color: rgba(255, 255, 255, 0.699);
    font-size: 11px;
    font-weight: 200;
    position: absolute;
    bottom: 15%;
  }

  .title {
    // color: white;
    font-size: 16px;
  }
  .rightIconWhite {
    // color: white;
    font-size: 24px;
    margin-right: 15px;
    position: relative;
  }
}

.titleText {
  font-family: $secondary-font;
  font-size: 16px;
  font-weight: bold;
  color: #000;
  padding-bottom: 3px;
  margin-top: 5px;
}


.topicTitle {
  color: $pwa-grey-1;
  font-size: 18px;
  font-weight: bold;
  font-family: $secondary-font;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 10px;
  padding-left: 20px;
}

.titleText {
  font-family: $secondary-font;
  font-size: 16px;
  font-weight: bold;
  // color: white;
  // padding-bottom: 3px;
}

