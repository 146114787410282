@import "~@/styles/themes.scss";
@import '../../../styles/themes.scss';

.container {
  margin-top: 50px;
  padding: 20px;
}

.briefingQuizTitle {
  font-family: $primary-font;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 15px;
}

.divider {
  height: 2px;
  width: 100%;
  border-radius: 1px;
  background-color: #DADADA;
  margin-top: 25px;
  margin-bottom: 25px;
}