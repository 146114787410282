@import "~@/styles/themes.scss";
.progressContent {
  display: flex;
  align-items: center;
  justify-content: center;
  //color: green;
}

.progressCircle {
  position: relative;
  border-radius: 15px;
  background-color: white;
  box-shadow: $admin-main-shadow;
  padding: 30px;
  top: 20px;
  left: 10px;

  :global {
    .ant-progress-text {
      font-size: 40px;
      font-weight: bold;
    }
  }
}

.numberPercentage {
  font-family: $primary-font;
}
.percentage {
  font-size: 20px;
  display: inline;
}
