@import "~@/styles/themes.scss";
.EventOverview {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

// EVENT DETAILS

.EventDetails {
  padding: 24px;
  background-color: $pwa-primary-color;
  position: relative;
  width: 100%;
  flex: 0 0 250px;

  .innerContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .eventInfo {
    margin-right: 20px;
    flex-grow: 1;
  }

  .imageContainer {
    margin-top: 15px;
    margin-bottom: 20px;
    text-align: center;
  }

  .imageCollapsedContainer {
    display: none;
  }

  .eventImage {
    width: 90%;
    object-fit: cover;
    height: 100px;
    border-radius: 20px;
    border: 1px solid #ffffff;
  }

  .imagePlaceholder {
    width: 90%;
    object-fit: contain;
    height: 100px;
    border-radius: 20px;
    border: 1px solid #ffffff;
    background-color: #061115;
  }
  @media (max-width: 300px) {
    .imagePlaceholder {
      object-fit: cover;
    }
  }

  .time {
    color: white;
    font-size: 14px;
    font-weight: bold;
  }

  .title {
    color: white;
    font-size: 16px;
    padding-right: 10px;
  }

  .backIcon {
    color: white;
    font-size: 24px;
    margin-right: 15px;
  }

  .announcementIconContainer {
    position: relative;
  }

  .announcementIcon {
    color: white;
  }
}

.EventDetailsCollapse {
  flex: 0 0 150px;
}

.alertIndicator {
  position: absolute;
  bottom: 3px;
  right: -7px;
  border-radius: 5px;
  width: 10px;
  height: 10px;
  background-color: #fb4953;
}

.eventContent {
  flex: 1;
  overflow-y: auto;
}

// TAB NAVIGATION

.TabNavigation {
  display: flex;
  position: absolute;
  border-radius: 15px;
  background-color: #ffffff;
  bottom: -30px;
  // top: 220px;
  padding: 5px;
  width: 90%;
  left: 5%;
  right: 5%;
  justify-content: space-evenly;
  z-index: 2;
}

.shadow {
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.08), 0 1px 18px 0 rgba(0, 0, 0, 0.1),
    0 3px 5px 0 rgba(0, 0, 0, 0.1);
}

.iconContainer {
  display: flex;
  padding: 10px;
  background-color: $pwa-grey-7;
  justify-content: center;
  align-items: center;
  border-radius: 9px;
  margin-left: 2px;
  margin-right: 2px;
  width: 100%;
}

.selected {
  background-color: $pwa-secondary-color;
}

.tabIcon {
  color: $pwa-grey-2;
}

.tabIconSelected {
  color: #000;
}

// NOTIFICATIONS

.notificationContainer {
  position: fixed;
  margin: -24px;
  padding-top: 40px;
  background-color: black;
  width: 100%;
}

.notificationContent {
  background-color: white;
  // height: 93%;
  height: 100vh;
  border-radius: 39px 39px 0 0;
  overflow-y: auto;
}

.doneButton {
  z-index: 100;
  position: absolute;
  top: 20px;
  right: 30px;
  font-family: $secondary-font;
  font-size: 16px;
  font-weight: 600;
  color: #0a0b09;
  text-align: center;
  width: 71px;
  padding: 10px;
  border-radius: 11.25px;
  background-color: #fcea25;
}
